<template>
  <b-container>
    <b-row>
      <b-col md="8" class="p-3 left-box">
        <p class="top-text">Test Details</p>
        <div class="pt-3 pb-4 pl-4 pr-4 prescription-box">
          <div class="d-flex justify-content-between">
            <p class="pres-text">Doctor Prescription <span>(Optional)</span></p>
            <b-icon
              icon="chevron-compact-down"
              style="cursor: pointer"
              font-scale="2"
              @click="toggleUploadOption"
            ></b-icon>
          </div>
          <div v-if="showUploadOption">
            <p class="instruct-text">
              Upload Your Prescription Which You Recieved From Your Doctor For
              Booking The Package
            </p>
            <div class="upload-box pt-3 pb-2">
              <img
                src="img/prescription-Vector.png"
                alt=""
                class="upload-icon pb-2"
                @click="$refs.fileUpload.click()"
                style="cursor: pointer"
              />
              <input
                ref="fileUpload"
                type="file"
                style="display: none"
                @change="imageSelection"
              />
              <p class="text-center upload-text">Upload Prescription</p>
            </div>
            <div
              class="doc-box d-flex py-2 px-3 mt-4 justify-content-between align-items-center"
            >
              <div class="d-flex align-items-center">
                <b-icon
                  icon="file-medical-fill"
                  style="color: #14982b; cursor: pointer"
                  font-scale="2"
                  @click="$refs.fileUpload.click()"
                ></b-icon>
                <p class="instruct-text marginless-p ml-2">
                  {{ uploadFileName }}
                </p>
              </div>
              <b-icon
                icon="trash-fill"
                style="color: #ff6868; cursor: pointer"
                font-scale="1.5"
                @click="deleteFile"
                v-if="isImageUploaded"
              ></b-icon>
            </div>
            <div class="d-flex justify-content-end mt-3 mr-2">
              <button
                class="btn btn-primary btn-col"
                @click="proceedToSelectSlot"
              >
                Next
              </button>
            </div>
          </div>
        </div>
        <div class="pt-3 pb-4 pl-4 pr-4 mt-3 prescription-box">
          <div class="d-flex justify-content-between r">
            <p class="pres-text">Select Date & Time</p>
            <b-icon
              icon="chevron-compact-down"
              style="cursor: pointer"
              @click="toggleDateTimeSection"
              font-scale="2"
            ></b-icon>
          </div>
          <div class="mt-2" v-if="showDateTimeSection">
            <b-row>
              <b-col md="5">
                <div
                  class="show-date py-3 px-3 mb-3 d-flex justify-content-between align-items-center"
                >
                  <div>{{ formattedDate }}</div>
                  <b-icon
                    icon="calendar2-week-fill"
                    font-scale="1.5"
                    style="color: #a3a3a3"
                  ></b-icon>
                </div>
                <DatePicker v-model="selectedDate" trim-weeks></DatePicker>
              </b-col>
              <b-col md="7">
                <p class="instruct-text ml-2 mb">Available Slots</p>
                <div class="mt-4 text-center" v-if="isLoading">
                  <b-icon
                    icon="arrow-clockwise"
                    animation="spin"
                    font-scale="4"
                  ></b-icon>
                </div>
                <div v-else-if="healthianSlotData.length > 0">
                  <div
                    class="slot-time text-center mr-3 mb-3"
                    v-for="(slot, i) in healthianSlotData"
                    :key="i"
                    @click="selectHealthianSlot(slot)"
                    :class="{
                      selectedSlot:
                        slot.stm_id === selectedHealthianSlot.stm_id,
                    }"
                  >
                    {{ slot.slot_time }} - {{ slot.end_time }}
                  </div>
                </div>

                <p class="pres-text" v-else>
                  Sorry! No Slots Available on
                  {{ selectedDate.toString().slice(0, 15) }}
                </p>
              </b-col>
            </b-row>
            <div class="d-flex justify-content-end mt-3 mr-2">
              <button
                :disabled="!isSlotSelected"
                class="btn btn-primary btn-col"
                @click="proceedToaddMembers"
              >
                Next
              </button>
            </div>
          </div>
        </div>
        <div class="pt-3 pb-4 pl-4 pr-4 mt-3 prescription-box">
          <div class="d-flex justify-content-between">
            <p class="pres-text mb-3">Add Member</p>
            <b-icon
              icon="chevron-compact-down"
              style="cursor: pointer"
              @click="toggleAddMembersSection"
              font-scale="2"
            ></b-icon>
          </div>
          <div v-if="showAddMembersSection">
            <b-row>
              <div
                class="d-flex doc-box p-2 wid mr-2 mb-2 wid"
                v-for="(member, i) in healthianMembers"
                :key="i"
              >
                <b-form-checkbox
                  id="checkbox-2"
                  v-model="status"
                  :value="member"
                  @change="onClickCheckbox"
                  unchecked-value="not_accepted"
                >
                </b-form-checkbox>
                <div>
                  <p class="pres-text">
                    {{ member.name.first }}({{
                      member.extendedAttributes.relation
                    }})
                  </p>
                  <div class="d-flex align-items-center">
                    <b-icon
                      icon="person-fill"
                      font-scale="1.2"
                      style="color: #c4c4c4"
                    ></b-icon>
                    <p class="marginless-p ml-2 name-text">
                      {{ member.extendedAttributes.gender }},
                      {{ member.extendedAttributes.age }}
                    </p>
                  </div>
                </div>
              </div>
            </b-row>
            <div class="d-flex justify-content-end mt-3 mr-2">
              <button
                class="btn btn-primary btn-col"
                @click="$bvModal.show('add-members')"
              >
                Add Members
              </button>
            </div>
            <b-modal
              id="add-members"
              size="lg"
              class="p-3"
              :hide-footer="true"
              :hide-header="true"
              :no-close-on-esc="true"
              :no-close-on-backdrop="true"
              centered
            >
              <b-container>
                <div class="d-flex justify-content-between align-items-center">
                  <p class="pop-header">Add New Member</p>
                  <b-icon
                    icon="plus-circle-fill"
                    rotate="45"
                    font-scale="1.5"
                    style="cursor: pointer"
                    @click="cancelModal"
                  ></b-icon>
                </div>
                <b-row class="mt-3">
                  <b-col>
                    <v-text-field
                      class="mb-2"
                      v-model.trim="patientName"
                      label="Patient
                       Name*"
                    ></v-text-field>
                  </b-col>
                  <b-col
                    ><v-text-field
                      class="mb-2"
                      type="number"
                      v-model.trim="mobileNumber"
                      label="Mobile Number*"
                    ></v-text-field
                  ></b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <v-text-field
                      class="mb-2"
                      type="email"
                      v-model.trim="emailId"
                      label="Email Id*"
                    ></v-text-field>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <v-text-field
                      class="mb-2"
                      type="date"
                      v-model.trim="dateOfBirth"
                      label="DOB*"
                    ></v-text-field>
                  </b-col>
                  <b-col>
                    <v-text-field
                      class="mb-2"
                      readonly
                      v-model="memberAge"
                      label="Age*"
                    ></v-text-field>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-select
                      v-model="selectedGender"
                      :options="options"
                    ></b-form-select>
                  </b-col>
                  <b-col>
                    <b-form-select
                      v-model="selectedRelation"
                      :options="relations"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <div class="d-flex justify-content-center mt-5">
                  <button
                    class="btn btn-outline-primary px-4"
                    @click="cancelModal"
                  >
                    Cancel
                  </button>
                  <button class="btn btn-primary ml-3 px-4" @click="addMembers">
                    Add Now
                  </button>
                </div>
              </b-container>
            </b-modal>
          </div>
        </div>
      </b-col>
      <b-col md="4" class="right-box">
        <div class="right-heading py-3 px-4">Package Details</div>
        <p class="pres-text mt-3">Selected Address</p>
        <div class="shadow-box p-3">
          <div class="d-flex justify-content-between">
            <div class="d-flex">
              <b-icon
                icon="arrow-up-circle-fill"
                class="mr-3 icon-col"
              ></b-icon>
              <h6 class="large">
                {{ defaultAddress ? defaultAddress.fullName : "" }}
              </h6>
            </div>
            <div class="d-flex default-class">
              <b-icon icon="check2" class="mr-1" font-scale="1"></b-icon>
              <p class="mid">Default</p>
            </div>
          </div>
          <div class="d-flex mt-2">
            <b-icon icon="geo-alt-fill" class="mr-3 mt-1 icon-col"></b-icon>
            <p class="sml">
              {{ defaultAddress ? defaultAddress.houseNumber : "" }},
              {{ defaultAddress ? defaultAddress.street2 : "" }},
              {{ defaultAddress ? defaultAddress.city : "" }},<br />
              {{ defaultAddress ? defaultAddress.state : "" }}, PIN-
              {{ defaultAddress ? defaultAddress.zip : "" }}
            </p>
          </div>
        </div>
        <p class="pres-text mt-3">Selected Package</p>
        <div class="shadow-box p-3">
          <p class="name-text marginless-p">{{ packageData.test_name }}</p>
          <div class="d-flex mt-2">
            <del
              class="name-text mr-3"
              style="color: #acacac"
              v-if="packageData.mrp !== null"
              >₹ {{ packageData.price }}/
            </del>
            <p class="name-text marginless-p" style="color: #0038ab">
              ₹ {{ packageAmount }}/-
            </p>
          </div>
        </div>
        <div v-if="Object.keys(checkoutData).length > 0">
          <p class="pres-text mt-3">Price Details</p>
          <div class="shadow-box p-3">
            <div class="d-flex justify-content-between">
              <p class="name-text marginless-p">SubTotal:</p>
              <p class="name-text marginless-p">₹ {{ checkoutSubTotal }}</p>
            </div>
            <div class="d-flex justify-content-between mt-2">
              <p class="name-text marginless-p">Collection Charge:</p>
              <p class="name-text marginless-p">₹ 00</p>
            </div>
            <hr class="my-2" />
            <div class="d-flex justify-content-between mt-2">
              <p class="large-text marginless-p">Your Total Amount:</p>
              <p class="large-text marginless-p">
                ₹ {{ checkoutData ? checkoutData.totalPrice : "" }}
              </p>
            </div>
          </div>
        </div>
        <template
          v-if="
            $options.components['checkout-transaction-benefits'] &&
            Object.keys(checkoutData).length > 0
          "
        >
          <checkout-transaction-benefits />
        </template>
        <div
          class="py-3 mt-4 proceed-box d-flex align-items-center justify-content-center"
          :class="{
            disbleBtn: !isCheckoutLoaded,
          }"
          @click="proceedToConfirmOrder"
        >
          Proceed
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from "vuex";
import { DatePicker } from "v-calendar";
import Vue from "vue";
import moment from "moment";

export default {
  data() {
    return {
      showUploadOption: true,
      showDateTimeSection: false,
      showAddMembersSection: false,
      isSlotSelected: false,
      packageData: {},
      selectedHealthianSlot: {},
      selectedDate: new Date(),
      isLoading: false,
      uploadFileName: "Select and Upload a File",
      isImageUploaded: false,
      selectedImageFile: "",
      status: "",
      patientName: "",
      mobileNumber: null,
      emailId: "",
      dateOfBirth: "",
      memberAge: null,
      selectedGender: "",
      selectedRelation: "",
      isCheckoutLoaded: false,
      options: [
        { text: "Select Gender", value: "" },
        { text: "Male", value: "Male" },
        { text: "Female", value: "Female" },
        { text: "Others", value: "Others" },
      ],
      relations: [
        { text: "Select Relation", value: "" },
        { text: "Self", value: "Self" },
        { text: "Spouse", value: "Spouse" },
        { text: "Son", value: "Son" },
        { text: "Daughter", value: "Daughter" },
        { text: "Mother", value: "Mother" },
        { text: "Father", value: "Father" },
        { text: "Grand Father", value: "Grand Father" },
        { text: "Grand Mother", value: "Grand Mother" },
        { text: "Other", value: "Other" },
      ],
    };
  },
  components: {
    DatePicker,
  },
  computed: {
    ...mapState({
      defaultAddress: (state) => state.address.defaultAddress,
      userInfo: (state) => state.auth.userInfo,
      packageList: (state) => state.healthCare.specificPackageList,
      healthianSlotData: (state) => state.healthCare.healthianSlotData,
      healthianMembers: (state) => state.healthCare.healthianMembers,
      productData: (state) => state.healthCare.productData,
      checkoutData: (state) => state.checkout.checkoutData,
    }),
    packageAmount() {
      if (this.packageData.mrp !== null) {
        return this.packageData.mrp;
      } else {
        return this.packageData.price;
      }
    },
    formattedDate() {
      return moment(this.selectedDate).format("yyyy-MM-DD");
    },
    checkoutSubTotal() {
      let total = 0;
      for (let i = 0; i < this.checkoutData.items.length; i++) {
        total = total + this.checkoutData.items[i].finalPrice;
      }
      return total;
    },
  },
  watch: {
    formattedDate() {
      this.fetchHealthianSlots();
    },
    dateOfBirth() {
      if (this.dateOfBirth !== "" && this.dateOfBirth.length > 8)
        this.calculateAge();
    },
  },
  methods: {
    toggleUploadOption() {
      this.showUploadOption = !this.showUploadOption;
    },
    toggleDateTimeSection() {
      this.showDateTimeSection = !this.showDateTimeSection;
    },
    toggleAddMembersSection() {
      if (this.isSlotSelected)
        this.showAddMembersSection = !this.showAddMembersSection;
    },
    proceedToSelectSlot() {
      this.showDateTimeSection = true;
    },
    proceedToaddMembers() {
      this.showAddMembersSection = true;
    },
    imageSelection(event) {
      this.uploadFileName = event.target.files[0].name;
      this.isImageUploaded = true;
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.selectedImageFile = reader.result;
        sessionStorage.setItem("prescription", reader.result);
      };
    },
    deleteFile() {
      this.uploadFileName = "Select and Upload a File";
      this.isImageUploaded = false;
      this.selectedImageFile = "";
    },
    selectHealthianSlot(slot) {
      this.selectedHealthianSlot = slot;
      this.isSlotSelected = true;
      this.$store.dispatch("healthCare/healthianSlotDataAction", slot);
      this.healthianCheckout();
    },
    onClickCheckbox() {
      this.healthianCheckout();
    },
    fetchPackageData() {
      this.packageData = this.packageList.find(
        (el) => el.deal_id == this.$route.params.profile
      );
    },
    calculateAge() {
      let today = new Date();
      let currentYear = today.getFullYear();
      let year = Number(this.dateOfBirth.slice(0, 4));
      let yearDiff = today.getFullYear() - year;
      let birthMonth = this.dateOfBirth.slice(5, 7) - 1;
      let birthdayThisYear = new Date(
        currentYear,
        birthMonth.toString(),
        this.dateOfBirth.slice(8, 10)
      );
      let hasBirthdayThisYear = today >= birthdayThisYear;
      if (hasBirthdayThisYear) {
        this.memberAge = yearDiff;
      } else {
        this.memberAge = yearDiff - 1;
      }
    },
    addMembers() {
      let payload = {
        name: {
          first: this.patientName,
          last: this.patientName,
        },
        authentication: {
          securityQuestions: {
            question: "what is your first school",
            answer: "no",
          },
          userName: new Date().getTime(),
          phone: this.mobileNumber,
          email: this.emailId,
          password: "123456",
        },
        referralCustomerNo: this.userInfo?.customerNo,
        parent: {
          _id: this.userInfo?._id,
        },
        extendedAttributes: {
          relation: this.selectedRelation,
          age: this.memberAge,
          dob: this.dateOfBirth,
          gender: this.selectedGender,
        },
      };
      this.$store
        .dispatch("healthCare/addMembersAction", payload)
        .then((res) => {
          console.log(res);
          this.$store.dispatch(
            "healthCare/fetchHealthianMembers",
            this.userInfo._id
          );
          this.cancelModal();
        });
    },
    async healthianCheckout() {
      if (this.isSlotSelected && this.status !== "") {
        this.isCheckoutLoaded = false;
        let payloadData = {
          item: this.productData?._id,
          checkout: {
            type: "CASHFREE",
            customerNo: this.userInfo?.customerNo,
            userEmail: this.userInfo?.authentication?.email,
            userPhone: this.userInfo?.authentication?.phone,
          },
          custom: {
            market: {
              name: "Healthians Booking",
              serviceProvider: "HEALTHIANS",
            },
            Amount: Number(this.packageAmount),
            packageName: this.packageData?.test_name,
            date: this.selectedHealthianSlot?.slot_date,
            time: this.selectedHealthianSlot?.slot_time,
            endTime: this.selectedHealthianSlot?.end_time,
            healthiansPayload: {
              customer: [
                {
                  customer_id: this.status?._id,
                  customer_name:
                    this.status?.name?.first + " " + this.status?.name.last,
                  relation: this.status?.extendedAttributes?.relation,
                  age: this.status?.extendedAttributes?.age,
                  dob: this.status?.extendedAttributes?.dob,
                  gender: this.status?.extendedAttributes?.gender,
                  contact_number: this.status?.authentication?.phone,
                  email: this.status?.authentication?.email,
                },
              ],
              slot: {
                slot_id: this.selectedHealthianSlot?.stm_id,
              },
              package: [
                {
                  deal_id: [this.packageData?.deal_id],
                },
              ],
              customer_calling_number: this.userInfo?.authentication?.phone,
              billing_cust_name:
                this.status?.name?.first + " " + this.status?.name.last,
              gender: this.status?.extendedAttributes?.gender,
              mobile: this.status?.authentication?.phone,
              email: this.status?.authentication?.email,
              state: this.selectedHealthianSlot?.state_id,
              cityId: this.selectedHealthianSlot?.city_id,
              sub_locality:
                this.defaultAddress?.street1 +
                ", " +
                this.defaultAddress?.street1 +
                ", " +
                this.defaultAddress?.city +
                ", " +
                this.defaultAddress?.zip,
              latitude: this.defaultAddress?.location?.coordinates[0],
              longitude: this.defaultAddress?.location?.coordinates[1],
              address: this.defaultAddress?.houseNumber,
              zipcode: this.defaultAddress?.zip,
              landmark: this.defaultAddress?.street2,
              altmobile: "",
              altemail: "",
              hard_copy: 0,
              vendor_booking_id: "",
              vendor_billing_user_id: this.userInfo?._id,
              payment_option: "cod",
              discounted_price: this.packageAmount,
            },
          },
        };

        await this.$store
          .dispatch("checkout/checkoutFinal", payloadData)
          .then((res) => {
            console.log(res);
            this.isCheckoutLoaded = true;
          });
      }
    },
    proceedToConfirmOrder() {
      if (!this.isSlotSelected && this.status == "") {
        console.log("both not selected");
        Vue.toasted.show("select slot date and  member ", {
          type: "info",
          position: "bottom-center",
          theme: "toasted-primary",
          singleton: true,
          duration: 3000,
        });
      } else if (!this.isSlotSelected) {
        console.log("slot not selected");
        Vue.toasted.show("select a slot ", {
          type: "info",
          position: "bottom-center",
          theme: "toasted-primary",
          singleton: true,
          duration: 3000,
        });
      } else if (this.status == "") {
        console.log("member not selected");
        Vue.toasted.show("select a member ", {
          type: "info",
          position: "bottom-center",
          theme: "toasted-primary",
          singleton: true,
          duration: 3000,
        });
      } else {
        this.$router.push({
          name: "healthCare_payment",
          params: { profile: this.packageData.deal_id },
        });
      }
    },
    cancelModal() {
      this.$bvModal.hide("add-members");
      this.patientName = "";
      this.mobileNumber = null;
      this.emailId = "";
      this.dateOfBirth = "";
      this.memberAge = null;
      this.selectedGender = "";
      this.selectedRelation = "";
    },
    async fetchHealthianSlots() {
      this.isLoading = true;
      let payload = {
        zipcode: this.defaultAddress?.zip,
        lat: this.defaultAddress?.location?.coordinates[0],
        long: this.defaultAddress?.location?.coordinates[1],
        slot_date: this.formattedDate,
        amount: Number(this.packageAmount),
        isDeviceSlot: 0,
      };
      await this.$store.dispatch("healthCare/fetchTimeSlotsAction", payload);
      this.isLoading = false;
    },
  },
  async mounted() {
    await this.fetchPackageData();
    await this.fetchHealthianSlots();
    await this.$store.dispatch(
      "healthCare/fetchHealthianMembers",
      this.userInfo._id
    );
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>

<style scoped>
.left-box {
  border: 1px solid #f5f5f5;
  border-radius: 10px;
  box-shadow: 0px 2px 8px #e4e2e2;
}
.prescription-box {
  background-color: #f3f7fb;
  border-radius: 10px;
}
.top-text {
  font-size: 21px;
  font-weight: 600;
  color: #00a0a8;
}
.upload-box {
  background-color: #f1f1f1;
  border: 2px dashed #557ac7;
  border-radius: 10px;
}
.pres-text {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 6px;
}
.pres-text span {
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
}
.instruct-text {
  font-size: 13px;
  font-weight: 600;
}
.upload-icon {
  display: block;
  margin: auto;
}
.wid {
  width: 268px;
}
.upload-text {
  font-size: 13px;
  color: #053cac;
  font-weight: 600;
  margin-bottom: 0px;
}
.doc-box {
  border: 1px solid #f0f0f0;
  background-color: #fff;
  border-radius: 8px;
}
.pop-header {
  font-size: 21px;
  font-weight: 600;
  color: #fd7e14;
  margin-bottom: 0px;
}
.marginless-p {
  margin-bottom: 0px;
}
.selectedSlot {
  border: 1px solid #0038ab !important;
  color: #0038ab !important;
}
.slot-time {
  border: 1px solid #cbd5e0;
  background-color: #fff;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  padding: 7px;
  min-width: 160px;
  min-width: 160px;
  font-size: 14px;
  font-weight: 500;
}
.right-heading {
  background-image: linear-gradient(to bottom right, #17dce8, #1da4ac);
  border-radius: 8px;
  color: #fff;
  font-size: 21px;
  font-weight: 500;
}
.shadow-box {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 8px #e4e2e2;
}
.show-date {
  background-color: #fff;
  border: 1px solid #cbd5e0;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 500;
}
.btn-col {
  background-color: #0038ab !important;
  border: 1px solid #0038ab;
}
.btn-col:hover {
  background-color: #0038ab !important;
  border: 1px solid #0038ab;
}
.large {
  font-size: 13px;
  margin-bottom: 0px;
}
.mid {
  font-size: 11px;
  margin-bottom: 0px;
}
.sml {
  font-size: 10px;
  margin-bottom: 0px;
}
.icon-col {
  color: #c4c4c4;
}
.default-class {
  color: #47ab51;
  font-weight: 500;
}
.name-text {
  font-size: 13px;
  font-weight: 500;
}
.large-text {
  font-size: 16px;
  font-weight: 600;
}
.proceed-box {
  border-radius: 8px;
  background-color: #0038ab;
  font-size: 21px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}
.disbleBtn {
  background-color: #5d7cba;
}
</style>
