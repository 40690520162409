var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', [_c('b-row', [_c('b-col', {
    staticClass: "p-3 left-box",
    attrs: {
      "md": "8"
    }
  }, [_c('p', {
    staticClass: "top-text"
  }, [_vm._v("Test Details")]), _c('div', {
    staticClass: "pt-3 pb-4 pl-4 pr-4 prescription-box"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('p', {
    staticClass: "pres-text"
  }, [_vm._v("Doctor Prescription "), _c('span', [_vm._v("(Optional)")])]), _c('b-icon', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "icon": "chevron-compact-down",
      "font-scale": "2"
    },
    on: {
      "click": _vm.toggleUploadOption
    }
  })], 1), _vm.showUploadOption ? _c('div', [_c('p', {
    staticClass: "instruct-text"
  }, [_vm._v(" Upload Your Prescription Which You Recieved From Your Doctor For Booking The Package ")]), _c('div', {
    staticClass: "upload-box pt-3 pb-2"
  }, [_c('img', {
    staticClass: "upload-icon pb-2",
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "src": "img/prescription-Vector.png",
      "alt": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.fileUpload.click();
      }
    }
  }), _c('input', {
    ref: "fileUpload",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file"
    },
    on: {
      "change": _vm.imageSelection
    }
  }), _c('p', {
    staticClass: "text-center upload-text"
  }, [_vm._v("Upload Prescription")])]), _c('div', {
    staticClass: "doc-box d-flex py-2 px-3 mt-4 justify-content-between align-items-center"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-icon', {
    staticStyle: {
      "color": "#14982b",
      "cursor": "pointer"
    },
    attrs: {
      "icon": "file-medical-fill",
      "font-scale": "2"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.fileUpload.click();
      }
    }
  }), _c('p', {
    staticClass: "instruct-text marginless-p ml-2"
  }, [_vm._v(" " + _vm._s(_vm.uploadFileName) + " ")])], 1), _vm.isImageUploaded ? _c('b-icon', {
    staticStyle: {
      "color": "#ff6868",
      "cursor": "pointer"
    },
    attrs: {
      "icon": "trash-fill",
      "font-scale": "1.5"
    },
    on: {
      "click": _vm.deleteFile
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "d-flex justify-content-end mt-3 mr-2"
  }, [_c('button', {
    staticClass: "btn btn-primary btn-col",
    on: {
      "click": _vm.proceedToSelectSlot
    }
  }, [_vm._v(" Next ")])])]) : _vm._e()]), _c('div', {
    staticClass: "pt-3 pb-4 pl-4 pr-4 mt-3 prescription-box"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between r"
  }, [_c('p', {
    staticClass: "pres-text"
  }, [_vm._v("Select Date & Time")]), _c('b-icon', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "icon": "chevron-compact-down",
      "font-scale": "2"
    },
    on: {
      "click": _vm.toggleDateTimeSection
    }
  })], 1), _vm.showDateTimeSection ? _c('div', {
    staticClass: "mt-2"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "show-date py-3 px-3 mb-3 d-flex justify-content-between align-items-center"
  }, [_c('div', [_vm._v(_vm._s(_vm.formattedDate))]), _c('b-icon', {
    staticStyle: {
      "color": "#a3a3a3"
    },
    attrs: {
      "icon": "calendar2-week-fill",
      "font-scale": "1.5"
    }
  })], 1), _c('DatePicker', {
    attrs: {
      "trim-weeks": ""
    },
    model: {
      value: _vm.selectedDate,
      callback: function callback($$v) {
        _vm.selectedDate = $$v;
      },
      expression: "selectedDate"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "7"
    }
  }, [_c('p', {
    staticClass: "instruct-text ml-2 mb"
  }, [_vm._v("Available Slots")]), _vm.isLoading ? _c('div', {
    staticClass: "mt-4 text-center"
  }, [_c('b-icon', {
    attrs: {
      "icon": "arrow-clockwise",
      "animation": "spin",
      "font-scale": "4"
    }
  })], 1) : _vm.healthianSlotData.length > 0 ? _c('div', _vm._l(_vm.healthianSlotData, function (slot, i) {
    return _c('div', {
      key: i,
      staticClass: "slot-time text-center mr-3 mb-3",
      class: {
        selectedSlot: slot.stm_id === _vm.selectedHealthianSlot.stm_id
      },
      on: {
        "click": function click($event) {
          return _vm.selectHealthianSlot(slot);
        }
      }
    }, [_vm._v(" " + _vm._s(slot.slot_time) + " - " + _vm._s(slot.end_time) + " ")]);
  }), 0) : _c('p', {
    staticClass: "pres-text"
  }, [_vm._v(" Sorry! No Slots Available on " + _vm._s(_vm.selectedDate.toString().slice(0, 15)) + " ")])])], 1), _c('div', {
    staticClass: "d-flex justify-content-end mt-3 mr-2"
  }, [_c('button', {
    staticClass: "btn btn-primary btn-col",
    attrs: {
      "disabled": !_vm.isSlotSelected
    },
    on: {
      "click": _vm.proceedToaddMembers
    }
  }, [_vm._v(" Next ")])])], 1) : _vm._e()]), _c('div', {
    staticClass: "pt-3 pb-4 pl-4 pr-4 mt-3 prescription-box"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('p', {
    staticClass: "pres-text mb-3"
  }, [_vm._v("Add Member")]), _c('b-icon', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "icon": "chevron-compact-down",
      "font-scale": "2"
    },
    on: {
      "click": _vm.toggleAddMembersSection
    }
  })], 1), _vm.showAddMembersSection ? _c('div', [_c('b-row', _vm._l(_vm.healthianMembers, function (member, i) {
    return _c('div', {
      key: i,
      staticClass: "d-flex doc-box p-2 wid mr-2 mb-2 wid"
    }, [_c('b-form-checkbox', {
      attrs: {
        "id": "checkbox-2",
        "value": member,
        "unchecked-value": "not_accepted"
      },
      on: {
        "change": _vm.onClickCheckbox
      },
      model: {
        value: _vm.status,
        callback: function callback($$v) {
          _vm.status = $$v;
        },
        expression: "status"
      }
    }), _c('div', [_c('p', {
      staticClass: "pres-text"
    }, [_vm._v(" " + _vm._s(member.name.first) + "(" + _vm._s(member.extendedAttributes.relation) + ") ")]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('b-icon', {
      staticStyle: {
        "color": "#c4c4c4"
      },
      attrs: {
        "icon": "person-fill",
        "font-scale": "1.2"
      }
    }), _c('p', {
      staticClass: "marginless-p ml-2 name-text"
    }, [_vm._v(" " + _vm._s(member.extendedAttributes.gender) + ", " + _vm._s(member.extendedAttributes.age) + " ")])], 1)])], 1);
  }), 0), _c('div', {
    staticClass: "d-flex justify-content-end mt-3 mr-2"
  }, [_c('button', {
    staticClass: "btn btn-primary btn-col",
    on: {
      "click": function click($event) {
        return _vm.$bvModal.show('add-members');
      }
    }
  }, [_vm._v(" Add Members ")])]), _c('b-modal', {
    staticClass: "p-3",
    attrs: {
      "id": "add-members",
      "size": "lg",
      "hide-footer": true,
      "hide-header": true,
      "no-close-on-esc": true,
      "no-close-on-backdrop": true,
      "centered": ""
    }
  }, [_c('b-container', [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('p', {
    staticClass: "pop-header"
  }, [_vm._v("Add New Member")]), _c('b-icon', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "icon": "plus-circle-fill",
      "rotate": "45",
      "font-scale": "1.5"
    },
    on: {
      "click": _vm.cancelModal
    }
  })], 1), _c('b-row', {
    staticClass: "mt-3"
  }, [_c('b-col', [_c('v-text-field', {
    staticClass: "mb-2",
    attrs: {
      "label": "Patient\n                     Name*"
    },
    model: {
      value: _vm.patientName,
      callback: function callback($$v) {
        _vm.patientName = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "patientName"
    }
  })], 1), _c('b-col', [_c('v-text-field', {
    staticClass: "mb-2",
    attrs: {
      "type": "number",
      "label": "Mobile Number*"
    },
    model: {
      value: _vm.mobileNumber,
      callback: function callback($$v) {
        _vm.mobileNumber = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "mobileNumber"
    }
  })], 1)], 1), _c('b-row', [_c('b-col', [_c('v-text-field', {
    staticClass: "mb-2",
    attrs: {
      "type": "email",
      "label": "Email Id*"
    },
    model: {
      value: _vm.emailId,
      callback: function callback($$v) {
        _vm.emailId = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "emailId"
    }
  })], 1)], 1), _c('b-row', [_c('b-col', [_c('v-text-field', {
    staticClass: "mb-2",
    attrs: {
      "type": "date",
      "label": "DOB*"
    },
    model: {
      value: _vm.dateOfBirth,
      callback: function callback($$v) {
        _vm.dateOfBirth = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "dateOfBirth"
    }
  })], 1), _c('b-col', [_c('v-text-field', {
    staticClass: "mb-2",
    attrs: {
      "readonly": "",
      "label": "Age*"
    },
    model: {
      value: _vm.memberAge,
      callback: function callback($$v) {
        _vm.memberAge = $$v;
      },
      expression: "memberAge"
    }
  })], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-select', {
    attrs: {
      "options": _vm.options
    },
    model: {
      value: _vm.selectedGender,
      callback: function callback($$v) {
        _vm.selectedGender = $$v;
      },
      expression: "selectedGender"
    }
  })], 1), _c('b-col', [_c('b-form-select', {
    attrs: {
      "options": _vm.relations
    },
    model: {
      value: _vm.selectedRelation,
      callback: function callback($$v) {
        _vm.selectedRelation = $$v;
      },
      expression: "selectedRelation"
    }
  })], 1)], 1), _c('div', {
    staticClass: "d-flex justify-content-center mt-5"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary px-4",
    on: {
      "click": _vm.cancelModal
    }
  }, [_vm._v(" Cancel ")]), _c('button', {
    staticClass: "btn btn-primary ml-3 px-4",
    on: {
      "click": _vm.addMembers
    }
  }, [_vm._v(" Add Now ")])])], 1)], 1)], 1) : _vm._e()])]), _c('b-col', {
    staticClass: "right-box",
    attrs: {
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "right-heading py-3 px-4"
  }, [_vm._v("Package Details")]), _c('p', {
    staticClass: "pres-text mt-3"
  }, [_vm._v("Selected Address")]), _c('div', {
    staticClass: "shadow-box p-3"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('b-icon', {
    staticClass: "mr-3 icon-col",
    attrs: {
      "icon": "arrow-up-circle-fill"
    }
  }), _c('h6', {
    staticClass: "large"
  }, [_vm._v(" " + _vm._s(_vm.defaultAddress ? _vm.defaultAddress.fullName : "") + " ")])], 1), _c('div', {
    staticClass: "d-flex default-class"
  }, [_c('b-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": "check2",
      "font-scale": "1"
    }
  }), _c('p', {
    staticClass: "mid"
  }, [_vm._v("Default")])], 1)]), _c('div', {
    staticClass: "d-flex mt-2"
  }, [_c('b-icon', {
    staticClass: "mr-3 mt-1 icon-col",
    attrs: {
      "icon": "geo-alt-fill"
    }
  }), _c('p', {
    staticClass: "sml"
  }, [_vm._v(" " + _vm._s(_vm.defaultAddress ? _vm.defaultAddress.houseNumber : "") + ", " + _vm._s(_vm.defaultAddress ? _vm.defaultAddress.street2 : "") + ", " + _vm._s(_vm.defaultAddress ? _vm.defaultAddress.city : "") + ","), _c('br'), _vm._v(" " + _vm._s(_vm.defaultAddress ? _vm.defaultAddress.state : "") + ", PIN- " + _vm._s(_vm.defaultAddress ? _vm.defaultAddress.zip : "") + " ")])], 1)]), _c('p', {
    staticClass: "pres-text mt-3"
  }, [_vm._v("Selected Package")]), _c('div', {
    staticClass: "shadow-box p-3"
  }, [_c('p', {
    staticClass: "name-text marginless-p"
  }, [_vm._v(_vm._s(_vm.packageData.test_name))]), _c('div', {
    staticClass: "d-flex mt-2"
  }, [_vm.packageData.mrp !== null ? _c('del', {
    staticClass: "name-text mr-3",
    staticStyle: {
      "color": "#acacac"
    }
  }, [_vm._v("₹ " + _vm._s(_vm.packageData.price) + "/ ")]) : _vm._e(), _c('p', {
    staticClass: "name-text marginless-p",
    staticStyle: {
      "color": "#0038ab"
    }
  }, [_vm._v(" ₹ " + _vm._s(_vm.packageAmount) + "/- ")])])]), Object.keys(_vm.checkoutData).length > 0 ? _c('div', [_c('p', {
    staticClass: "pres-text mt-3"
  }, [_vm._v("Price Details")]), _c('div', {
    staticClass: "shadow-box p-3"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('p', {
    staticClass: "name-text marginless-p"
  }, [_vm._v("SubTotal:")]), _c('p', {
    staticClass: "name-text marginless-p"
  }, [_vm._v("₹ " + _vm._s(_vm.checkoutSubTotal))])]), _c('div', {
    staticClass: "d-flex justify-content-between mt-2"
  }, [_c('p', {
    staticClass: "name-text marginless-p"
  }, [_vm._v("Collection Charge:")]), _c('p', {
    staticClass: "name-text marginless-p"
  }, [_vm._v("₹ 00")])]), _c('hr', {
    staticClass: "my-2"
  }), _c('div', {
    staticClass: "d-flex justify-content-between mt-2"
  }, [_c('p', {
    staticClass: "large-text marginless-p"
  }, [_vm._v("Your Total Amount:")]), _c('p', {
    staticClass: "large-text marginless-p"
  }, [_vm._v(" ₹ " + _vm._s(_vm.checkoutData ? _vm.checkoutData.totalPrice : "") + " ")])])])]) : _vm._e(), _vm.$options.components['checkout-transaction-benefits'] && Object.keys(_vm.checkoutData).length > 0 ? [_c('checkout-transaction-benefits')] : _vm._e(), _c('div', {
    staticClass: "py-3 mt-4 proceed-box d-flex align-items-center justify-content-center",
    class: {
      disbleBtn: !_vm.isCheckoutLoaded
    },
    on: {
      "click": _vm.proceedToConfirmOrder
    }
  }, [_vm._v(" Proceed ")])], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }